<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: fromBranchPage?'Branches':'StaffList',
              }"
              >{{ fromBranchPage ? '分店管理' : '帳號管理' }}</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: fromBranchPage?'BranchStaffs':'StaffList',
                params: {
                  branchID: this.$route.params.branchID,
                }
              }"
              >{{ fromBranchPage ? '店內帳號' : '帳號列表' }}</b-breadcrumb-item
            >
            <b-breadcrumb-item active>設定角色</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            設定角色
          </h4>
        </div>
        <b-card-body>
          <div class="row">
            <div class="col-12 col-xl-8 mt-3">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="姓名"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.name"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.employee_code"
                  disabled
                ></b-form-input
              ></b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="角色"
              >
                <p v-if="showRoleCode">{{ this.roleCode }}(不存在)</p>
                <b-form-select
                  class="form-control"
                  v-model="staff.role_id"
                >
                  <b-form-select-option :value="null" disabled
                  >請選擇角色
                  </b-form-select-option
                  >
                  <b-form-select-option
                    v-for="(role, index) in roles"
                    :key="index"
                    :value="role.id"
                  >{{ `${role.code} ${role.name}` }}
                  </b-form-select-option
                  >
                </b-form-select>
              </b-form-group
              >
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="float-right">
                    <b-button
                      class="mr-3"
                      @click="handleCancel"
                      variant="outline-danger"
                      >返回</b-button
                    >
                    <b-button @click="submit" variant="success">更新</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import staffApi from "@/apis/staff";
import roleApi from "@/apis/role";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("general", ["organization"]),
    ...mapGetters("route", ["routeQuery"]),
    fromBranchPage() {
      return !!this.$route.params.branchID;
    },
  },
  data() {
    return {
      staff: {
        name: null,
        employee_code: null,
        role_id: null,
        role_code: null
      },
      showLoading: true,
      showRoleCode: false,
      roleCode: null,
      roles: [],
    };
  },
  async mounted() {
    await this.fetchStaff();
    this.fetchRoles();
  },
  watch: {
    "staff.role_id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.staff.role_code = this.roles.find(role => role.id === newVal).code;
      }
    },
  },
  methods: {
    async fetchStaff() {
      const staff_id = this.$route.params.staff_id;

      let response = await staffApi.getOneStaff(staff_id);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
        this.branches = this.staff.branches.filter(branch => {
          return branch.org_id == this.organization.id;
        })
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
    },
    async submit() {
      this.showLoading = true;

      try {
        let response = await staffApi.updateStaff(this.staff);
        if (response.status && response.status === 200) {
          this.$swal
            .fire({
              type: "success",
              icon: "success",
              text: "設定完成",
            })
            .then(() => {
              this.goListPage();
            });
        }
      } catch (error) {
        console.error(error);
        alert("設定角色失敗");
      }

      this.showLoading = false;
    },
    goListPage() {
      if (this.fromBranchPage) {
        this.$router.push({ name: "BranchStaffs" });
      } else {
        this.$router.push({ name: "StaffList", query: this.routeQuery });
      }
    },
    handleCancel() {
      this.goListPage();
    },
    async fetchRoles() {
      const {data} = await roleApi.getRoles(this.organization.id);
      this.roles = data.data;

      this.roleCode = this.staff.organizations.find(organization => {
        return organization.code == this.organization.code
      }).pivot.role

      this.staff.role_id = this.roles.find(role => {
        return role.code == this.roleCode
      })?.id
      this.showRoleCode = (!this.staff.role_id && this.roleCode) ? true : false;
      this.showLoading = false;
    },
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
